import React, { useState, useEffect } from 'react';
import { alertWindowHome } from '../../redux';
import { useDispatch } from 'react-redux';

export const AlertWindow = () => {
	const today = new Date().toLocaleString('en-us', { month: 'long', year: 'numeric' });
	const [showAlert, setShowAlert] = useState(true);
	const dispatch = useDispatch();

	const dismissAction = () => {
		dispatch(alertWindowHome(false));
		setShowAlert(false);
	}

	useEffect(() => {
		const alertEndDate = new Date('2024-10-14T17:00:00'); // 10-14-2024 at 5 PM ADO #949932
		const now = new Date();

		if (now > alertEndDate) {
			setShowAlert(false);
		}
	}, []);

	return (
		<div className='callout'>
			<div className="callout-msg">
				<span>{today}</span>
				<h2>Welcome to iconnect</h2>
				{showAlert ? (
					<div className='text-danger'>
						<strong>ALERT:</strong> Due to the damage from Hurricane Milton, our team remains focused on restoring outages as quickly as possible. There is significant damage that requires coordination with power companies to make the area safe and replace poles before work can be started to restore services. Our team is partnering with power companies and carriers as needed to restore services in a timely manner. </div>
				) : (
					<p>
						Your new Windstream Wholesale customer portal has arrived!
						<br />
						<br />
						iconnect offers multi-layer network data and self-service tools, providing unprecedented visibility into both wavelength and fiber performance. Reveal more and manage confidently with network intelligence as an extension of your network!
					</p>
				)}
			</div>
			<div className="callout-rhs">
				<div className="callout-actions">
					<div className="dismiss" onClick={() => dismissAction()}>
						<svg className="ico-link ico-add ico-close">
							<path
								d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z" />
						</svg>
					</div>
					<div className="cbg">
						<svg viewBox="0 0 127.8 336.48">
							<path d="M205.88,114A54.18,54.18,0,0,1,153.4,97.78c-17.91-19.68-20-51.47.91-74H103.2V360.24H231V100.38A55.12,55.12,0,0,1,205.88,114Z" transform="translate(-103.2 -23.76)" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	)
}