import {
	ACCOUNT_VERTICAL,
	ACNA,
	ACNAS,
	AE,
	AGENT_FLAG,
	API_ADDRESS_FLAG,
	API_EMAIL,
	API_ENABLED,
	BAN,
	BILLING_PROFILE_GLOBAL_ID,
	CAM,
	CAMP_ACCOUNTS,
	CAMP_ACCOUNTS_DOWNLOAD,
	COLOCATION,
	COLOCATION_DISCOUNT,
	COMMS_ACCOUNT,
	COMPANY,
	F_NAME,
	L_NAME,
	DARK_FIBER,
	DARK_FIBER_DISCOUNT,
	DIA_POP,
	DIA_POP_DISCOUNT,
	DIA_PREM,
	DIA_PREM_DISCOUNT,
	E_ACCESS,
	E_ACCESS_DISCOUNT,
	E_ACCESS_NNI,
	E_ACCESS_NNI_DISCOUNT,
	EIS,
	ELINE,
	ELINE_DISCOUNT,
	EMAIL,
	EXT,
	FIXED_WIRELESS_EACCESS,
	FIXED_WIRELESS_LAYER3,
	JURISDICTION,
	MANAGED_SPECTRUM,
	MANAGED_SPECTRUM_DISCOUNT,
	MPLS_AGG_NNI,
	MPLS_AGG_NNI_DISCOUNT,
	MPLS_AGG_TAIL,
	MPLS_AGG_TAIL_DISCOUNT,
	MPLS_INTERNET,
	MPLS_INTERNET_DISCOUNT,
	BROADBAND,
	BROADBAND_DISCOUNT,
	NCTC,
	NNIS,
	OFFNET_VENDOR_VISIBLE,
	ONNET_ONLY,
	PHONE,
	PRIVATE_LINE,
	PRIVATE_LINE_DISCOUNT,
	PROFILE_CHANGE,
	PROFILE_RESET,
	SE,
	SELECTED_PROFILE,
	UNIQUE_ID,
	NEW_PROFILE,
	PROFILE_USER_TABLE,
	PROFILE_HISTORY
} from "./types/actionTypes"

export const selectedProfile = (payload) => {
	return {
		type: SELECTED_PROFILE,
		payload: payload
	}
}

export const company = (payload) => {
	return {
		type: COMPANY,
		payload: payload
	}
}

export const lName = (payload) => {
	return {
		type: L_NAME,
		payload: payload
	}
}

export const fName = (payload) => {
	return {
		type: F_NAME,
		payload: payload
	}
}

export const email = (payload) => {
	return {
		type: EMAIL,
		payload: payload
	}
}

export const phone = (payload) => {
	return {
		type: PHONE,
		payload: payload
	}
}

export const ext = (payload) => {
	return {
		type: EXT,
		payload: payload
	}
}

export const jurisdiction = (payload) => {
	return {
		type: JURISDICTION,
		payload: payload
	}
}

export const account_vertical = (payload) => {
	return {
		type: ACCOUNT_VERTICAL,
		payload: payload
	}
}

export const agent_flag = (payload) => {
	return {
		type: AGENT_FLAG,
		payload: payload
	}
}

export const api_address_flag = (payload) => {
	return {
		type: API_ADDRESS_FLAG,
		payload: payload
	}
}

export const ae = (payload) => {
	return {
		type: AE,
		payload: payload
	}
}

export const cam = (payload) => {
	return {
		type: CAM,
		payload: payload
	}
}

export const se = (payload) => {
	return {
		type: SE,
		payload: payload
	}
}

export const fixedWirelessLayer3 = (payload) => {
	return {
		type: FIXED_WIRELESS_LAYER3,
		payload: payload
	}
}

export const fixedWirelessEAccess = (payload) => {
	return {
		type: FIXED_WIRELESS_EACCESS,
		payload: payload
	}
}

export const onNetOnly = (payload) => {
	return {
		type: ONNET_ONLY,
		payload: payload
	}
}

export const nctc = (payload) => {
	return {
		type: NCTC,
		payload: payload
	}
}

export const eis = (payload) => {
	return {
		type: EIS,
		payload: payload
	}
}

export const offnetVendorVisible = (payload) => {
	return {
		type: OFFNET_VENDOR_VISIBLE,
		payload: payload
	}
}

export const billingProfileGlobalID = (payload) => {
	return {
		type: BILLING_PROFILE_GLOBAL_ID,
		payload: payload
	}
}

export const commsAccount = (payload) => {
	return {
		type: COMMS_ACCOUNT,
		payload: payload
	}
}

export const ban = (payload) => {
	return {
		type: BAN,
		payload: payload
	}
}

export const acna = (payload) => {
	return {
		type: ACNA,
		payload: payload
	}
}

export const resetProfile = (payload) => {
	return {
		type: PROFILE_RESET,
		payload: payload
	}
}

export const profileChange = (payload) => {
	return {
		type: PROFILE_CHANGE,
		payload: payload
	}
}

// CAMP ACCOUNTS
export const acnas = (payload) => {
	return {
		type: ACNAS,
		payload: payload
	}
}

export const camp_accounts = (payload) => {
	return {
		type: CAMP_ACCOUNTS,
		payload: payload
	}
}


export const camp_accounts_download = (payload) => {
	return {
		type: CAMP_ACCOUNTS_DOWNLOAD,
		payload: payload
	}
}

// Products
export const PrivateLine = (payload) => {
	return {
		type: PRIVATE_LINE,
		payload: payload
	}
}
export const MPLSAggTail = (payload) => {
	return {
		type: MPLS_AGG_TAIL,
		payload: payload
	}
}
export const MPLSInternet = (payload) => {
	return {
		type: MPLS_INTERNET,
		payload: payload
	}
}
export const MPLSAggNNI = (payload) => {
	return {
		type: MPLS_AGG_NNI,
		payload: payload
	}
}
export const DIAPOP = (payload) => {
	return {
		type: DIA_POP,
		payload: payload
	}
}
export const DIAPrem = (payload) => {
	return {
		type: DIA_PREM,
		payload: payload
	}
}
export const Colocation = (payload) => {
	return {
		type: COLOCATION,
		payload: payload
	}
}
export const ELine = (payload) => {
	return {
		type: ELINE,
		payload: payload
	}
}
export const EAccess = (payload) => {
	return {
		type: E_ACCESS,
		payload: payload
	}
}
export const EAccessNNI = (payload) => {
	return {
		type: E_ACCESS_NNI,
		payload: payload
	}
}
export const DarkFiber = (payload) => {
	return {
		type: DARK_FIBER,
		payload: payload
	}
}
export const ManagedSpectrum = (payload) => {
	return {
		type: MANAGED_SPECTRUM,
		payload: payload
	}
}
export const Broadband = (payload) => {
	return {
		type: BROADBAND,
		payload: payload
	}
}
// Discounts
export const PrivateLineDiscount = (payload) => {
	return {
		type: PRIVATE_LINE_DISCOUNT,
		payload: payload
	}
}
export const MPLSAggTailDiscount = (payload) => {
	return {
		type: MPLS_AGG_TAIL_DISCOUNT,
		payload: payload
	}
}
export const MPLSInternetDiscount = (payload) => {
	return {
		type: MPLS_INTERNET_DISCOUNT,
		payload: payload
	}
}
export const MPLSAggNNIDiscount = (payload) => {
	return {
		type: MPLS_AGG_NNI_DISCOUNT,
		payload: payload
	}
}
export const DIAPOPDiscount = (payload) => {
	return {
		type: DIA_POP_DISCOUNT,
		payload: payload
	}
}
export const DIAPremDiscount = (payload) => {
	return {
		type: DIA_PREM_DISCOUNT,
		payload: payload
	}
}
export const ColocationDiscount = (payload) => {
	return {
		type: COLOCATION_DISCOUNT,
		payload: payload
	}
}
export const ELineDiscount = (payload) => {
	return {
		type: ELINE_DISCOUNT,
		payload: payload
	}
}
export const EAccessDiscount = (payload) => {
	return {
		type: E_ACCESS_DISCOUNT,
		payload: payload
	}
}
export const EAccessNNIDiscount = (payload) => {
	return {
		type: E_ACCESS_NNI_DISCOUNT,
		payload: payload
	}
}
export const DarkFiberDiscount = (payload) => {
	return {
		type: DARK_FIBER_DISCOUNT,
		payload: payload
	}
}
export const ManagedSpectrumDiscount = (payload) => {
	return {
		type: MANAGED_SPECTRUM_DISCOUNT,
		payload: payload
	}
}

export const BroadbandDiscount = (payload) => {
	return {
		type: BROADBAND_DISCOUNT,
		payload: payload
	}
}
export const nnis = (payload) => {
	return {
		type: NNIS,
		payload: payload
	}
}
export const api_enabled = (payload) => {
	return {
		type: API_ENABLED,
		payload: payload
	}
}
export const api_email = (payload) => {
	return {
		type: API_EMAIL,
		payload: payload
	}
}
export const unique_id = (payload) => {
	return {
		type: UNIQUE_ID,
		payload: payload
	}
}
export const newProfile = (payload) => {
	return {
		type: NEW_PROFILE,
		payload: payload
	}
}

export const profileUserTable = (payload) => {
	return {
		type: PROFILE_USER_TABLE,
		payload: payload
	}
}

export const profileHistory = (payload) => {
	return {
		type: PROFILE_HISTORY,
		payload: payload
	}
}
