import { useCallback, useEffect, useState, useMemo } from 'react';
import Api from '../../services/Api';
import ViewportWarning from "../shared/ViewportWarning";
import TabsOrderManager from "./includes/TabsOrderManager";
import Select from 'react-select';
import { connect, useSelector } from 'react-redux';
import style from './MACD.module.css';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { Redirect } from 'react-router-dom';
import Loader from '../shared/Loader';
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from "../shared/CompanyDropdown";

import {
	resetMACDData,
	setMACDCircuitListCount,
	setMACDCircuitGaid,
	setMACDCircuitSearch,
	setMACDAAddress,
	setMACDAAddress2,
	setMACDACity,
	setMACDActivityRequest,
	setMACDActivityType,
	setMACDAState,
	setMACDAZip,
	setMACDCircuitDetails,
	setMACDDiscoNetwork,
	setMACDExistingCircuit,
	setMACDInsertNotes,
	setMACDAccountNumber,
	setMACDManualCircuit,
	setMACDOrderNotes,
	setMACDProduct,
	setMACDStepCode,
	setMACDUploadType,
	setMACDZAddress,
	setMACDZAddress2,
	setMACDZCity,
	setMACDZState,
	setMACDZZip
} from '../../redux';
import MACDCustomerInfo from './MACDCustomerInfo';
import MACDServiceInfo from './MACDServiceInfo';
import MACDSiteInfo from './MACDSiteInfo';
import MACDHelper from '../../services/MACDService';
import OrderHelper from '../../services/OrderService';
import CircuitListHelper from '../../services/CircuitListService';


const MACD = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const stepList = ['Product Selection', 'Customer Information', 'Service Information', 'Site Information', 'Review & Create Order'];

	//State Variables
	const [hasError, setHasError] = useState(null);
	const [orderCreateForm, setOrderCreateForm] = useState([]);
	const [errorMSG, setErrorMSG] = useState('');
	const [fileErrorMSG, setFileErrorMSG] = useState('');
	const [successMSG, setSuccessMSG] = useState('');
	const [popupShow, setPopupShow] = useState(false);
	const [products, setProducts] = useState([]);
	const [activityTypesList, setActivityTypesList] = useState([]);
	const [activityTypes, setActivityTypes] = useState([]);
	const [siteInformationDisabled, setSiteInformationDisabled] = useState(false);
	const [circuitList, setCircuitList] = useState([]);
	const [selectedCircuit, setSelectedCircuit] = useState(false);
	const [uploadTypeOptions, setUploadTypeOptions] = useState([]);
	const [fileType, setFileType] = useState("");
	const [leaseFeeData, setLeaseFeeData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [fileLimit, setFileLimit] = useState(false);
	const [fileCounter, setFileCounter] = useState(0);
	const [redirectFlag, setRedirectFlag] = useState(false);
	const [macdErrorMsg, setMACDErrorMsg] = useState("");
	const [refId, setRefId] = useState("");
	const [file1, setFile1] = useState([]);
	const [file2, setFile2] = useState([]);
	const [file3, setFile3] = useState([]);
	const [file4, setFile4] = useState([]);
	const [file5, setFile5] = useState([]);
	const [fileTypeName, setFileTypeName] = useState("");
	const [mpid, setMPID] = useState([]);
	const [userFlag, setUserFlag] = useState(false);

	//Redux Store Variables used for the MACD Submission
	const step = useSelector(state => state?.MACDOrder?.activeStepCode);
	const macDFromStore = useSelector(state => state?.MACDOrder);
	const selectedProduct = useSelector(state => state?.MACDOrder?.selectedProduct);
	const selectedActivityRequest = useSelector(state => state?.MACDOrder?.selectedActivityRequest);
	const selectedActivityType = useSelector(state => Number(state?.MACDOrder.selectedActivityType?.macdId));
	const selectedCircuitListCount = useSelector(state => state?.MACDOrder.circuitListCount);
	const selectedCircuitSearch = useSelector(state => state?.MACDOrder.circuitSearch);
	const selectedMACDNotes = useSelector(state => state?.MACDOrder.Order_Notes.ORDER_NOTES);
	const selectedMACDProduct = useSelector(state => state?.MACDOrder.selectedProduct.productId);
	const selectedMACDServiceInfo = useSelector(state => state?.MACDOrder.ServiceInformation);
	const selectedMACDContacts = useSelector(state => state?.MACDOrder.CONTACTS);
	const selectedMACDGeneralInfo = useSelector(state => state?.MACDOrder.GeneralOrderInformation);
	const selectedMACDLocationA = useSelector(state => state?.MACDOrder.LocationA);
	const selectedMACDLocationZ = useSelector(state => state?.MACDOrder.LocationZ);
	const selectedMACDShippingLocation = useSelector(state => state?.MACDOrder.ShippingLocation);
	const selectedMACDFiles = useSelector(state => state?.MACDOrder.File_Attachment);
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	//Local Storage Variables
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');


	//Resetting the MACD form and MACD Store
	const formReset = useCallback(() => {
		logger.info("Inside MACD formReset");
		props.updateStepCode(1);
		props.clearMACDStore();
		setSelectedCircuit(false);
		setCircuitList([]);
		setRedirectFlag(false);
		setLeaseFeeData([]);
		setUploadedFiles([]);
		setFileCounter(0);
		setFileType("");
		setFileTypeName("");
		setFileLimit(false);
		setSiteInformationDisabled(false);
	}, [logger, props])

	useEffect(() => {
		if (selectedInternalMpid) {
			//reset the MACD form on change of internal company
			formReset();
		}
	}, [formReset, selectedInternalMpid]);

	useEffect(() => {
		logger.info("Inside MACD useEffect to get products, activity types and getMACDMpid");
		let finalProducts = [];
		setLoader(true);
		if (isCustomer || (isEmployee && selectedInternalMpid)) {
			logger.info("Fetching MACD Products and Activity Types");
			//Get mapping of all the activity types and activity requests to the different products
			MACDHelper.fetchMACDProducts().then(response => {
				finalProducts = response.data;
				logger.info(`fetchMACDProducts RESPONSE: ${JSON.stringify(response.data)}`);
				MACDHelper.getAccountProfile().then(response => {
					logger.info(`getAccountProfile RESPONSE: ${JSON.stringify(response.data)}`);
					setMPID(response.data.mpid);

					//Filter out SD-WAN if user is not reseller
					if (response.data?.acct_type !== "Reseller") {
						finalProducts = finalProducts.filter(product => product.code !== "SD_WAN");
					}

					//Dark Fiber is only available for 4718 and 8722 (uniti)
					if (Number(response.data.mpid) !== 4718 && Number(response.data.mpid) !== 8722) {
						finalProducts = finalProducts.filter(product => product.code !== "DARK_FIBER");
					}
					setProducts(finalProducts);
					setUserFlag(true);
				}).catch(error => {
					logger.error(`Error getting MACD USER: ${error}`);
					setLoader(false);
					setUserFlag(true);
				})
				setProducts(response.data);
			}).catch(error => {
				logger.error(`fetchMACDProducts ERROR: ${error}`)
				setHasError(error);
			})

			//Get the list of all MACD Activity Types
			MACDHelper.fetchMACDActivityTypes().then(response => {
				logger.info(`fetchMACDActivityTypes RESPONSE: ${JSON.stringify(response.data)}`);
				setActivityTypesList(response.data);
			}).catch(error => {
				logger.error(`fetchMACDActivityTypes ERROR: ${error}`)
				setHasError(error);
			})
		}
		setLoader(false);

	}, [isCustomer, isEmployee, selectedInternalMpid, logger])


	//Clearing any macd error message everytime the store changes
	useEffect(() => {
		setErrorMSG('');
		setFileErrorMSG('');
		setMACDErrorMsg('');
	}, [macDFromStore])

	//Function used to fetch available circuits to populate the circuit detail dropdown menu
	const fetchCircuitDetails = () => {
		setLoader(true);
		let circuitMenu = [];
		CircuitListHelper.getCircuitList(selectedCircuitSearch).then(response => {
			if (response.data !== "No circuits found") {
				circuitMenu = response.data;
			}
			setCircuitList([{ circuit: '', circuitId: 'Select Circuit' }].concat(circuitMenu));
			if (isEmployee) {
				logger.info("Adding Manual Input for Circuit Id's");
				setCircuitList([{ circuit: 'manual', circuitId: 'Manual Circuit' }].concat(circuitMenu));
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error Getting MACD Circuits: ${error}`);
			setHasError(error);
			setLoader(false);
		});
	}

	const fetchCircuitListCount = () => {
		logger.info("inside of fetchCircuitListCount");
		setLoader(true);
		CircuitListHelper.getCircuitListCount(selectedCircuitSearch).then(response => {
			logger.info(`Circuit List Count: ${response.data}`);
			props.updateCircuitListCount(response.data);
			setLoader(false);
			if (response.data < 5000) {
				logger.info(`The circuit count of the customer selected is ${response.data}`);
				fetchCircuitDetails();
			} else {
				logger.info(`Too many circuits the UI will display an additional field to limit circuit picklist`);
			}
		}).catch(error => {
			setLoader(false);
			logger.info(`Error Getting MACD Circuits: ${error}`);
		})
	}

	//Additional Circuit Search to manage accounts with extensive circuit lists that may lead to timeout problems
	const searchCircuitList = () => {
		if (selectedCircuitSearch === "") {
			return;
		}
		fetchCircuitListCount(selectedCircuitSearch);
	}


	//Popup Handler for cancel popup
	const cancelAction = () => {
		setErrorMSG('');
		setFileErrorMSG('');
		setSuccessMSG('');
		setPopupShow(true);
	}

	//Handler for next button
	const nextPage = () => {
		validationStep();
	}

	//Handler for previous button
	const prevPage = () => {
		if (step > 1) {
			let prevStep = step - 1;
			props.updateStepCode(prevStep);
		}
		if (step === 5) {
			if (siteInformationDisabled) {
				props.updateStepCode(3);
			}
		}

	}

	//Main Validation Function that checks all mandatory fields are filled in before going to the next step
	const validationStep = () => {
		let flag = 0;
		let overrideFlag = 0;

		if (step === 1) {
			if (!selectedInternalMpid && isEmployee) {
				flag = 1;
				setErrorMSG('Please select a Company.');
			} else if (!selectedProduct) {
				flag = 1;
				setErrorMSG('Please select a Product.');
			} else if (!selectedActivityRequest) { // ADD Change or DISCOUNT
				flag = 1;
				setErrorMSG('Please select an Activity.');
			} else if (selectedActivityType === undefined && activityTypes.length > 0) {
				flag = 1;
				setErrorMSG('Please select an Activity Type.');
			} else if (![15, 24, 27, 30].includes(selectedMACDProduct) && !selectedCircuit && !macDFromStore?.selectedCircuitDetails?.value) {
				flag = 1;
				setErrorMSG('Please select a Circuit ID.');
			} else if ((selectedMACDProduct === 15 || selectedMACDProduct === 24) && !selectedMACDServiceInfo.ACCOUNT_NUMBER) {
				flag = 1;
				setErrorMSG('Please enter an Account Number.');
			} else {
				flag = 0;
				setErrorMSG();
			}

			/*Manual Circuit Override Validation Check*/
			if (macDFromStore?.selectedCircuitDetails?.value === "manual") {
				if (!selectedMACDServiceInfo.MANUAL_CIRCUIT[0]) {
					flag = 1;
					setErrorMSG('Please enter an Manual Circuit.');
				} else if (selectedMACDServiceInfo.DISCO_NETWORK?.length === 0) {
					flag = 1;
					setErrorMSG('Please select a Network Identifier.');
				} else {
					flag = 0;
					setErrorMSG();
				}
			}
		}

		if (step === 2) {
			customerInfomationFields(orderCreateForm);
		}

		if (step === 3) {
			serviceInformationFields(orderCreateForm);
			//Adding additional error check multi circuit selection
			//ADO-899656 Bypass CID entry for Colo Change Orders
			if (selectedMACDServiceInfo.QUANTITY > 1 && selectedMACDProduct !== 15) {
				for (let i = 0; i < selectedMACDServiceInfo.QUANTITY; i++) {
					if (macDFromStore?.selectedCircuitDetails?.value === "manual") {
						if (!selectedMACDServiceInfo.MANUAL_CIRCUIT[i]) {
							flag = 1;
							setErrorMSG('Please enter a valid Existing Circuit ID for total quantity entered. ');
						}
					} else {
						if (!selectedMACDServiceInfo.EXISTING_CIRCUIT_ID[i]) {
							flag = 1;
							setErrorMSG('Please enter a valid Existing Circuit ID for total quantity entered. ');
						}
					}

				}
			}
		}

		if (step === 4) {
			logger.info("Step 4 Validation");
			siteInformationFields(orderCreateForm);
		}


		/*Loops through the orderCreateForm to see what fields are mandatory and determines if any required fields are empty in the store
		*Comment this portion out if you want to disable validation for testing purposes*/
		orderCreateForm.forEach((OC, i) => {
			if (OC.step === step) {
				OC.characteristics.forEach(CH => {
					if (macDFromStore[OC.code][CH.code] === '' && CH.mandatory && CH.visible) {
						logger.info(`The mandatory field that is empty in the MACDOrder.${OC.code}.${CH.code}`);
						flag = 1;
						setErrorMSG('Please verify all required fields are completed.');
						return;
					}
				});
			}
		});

		//Skipping Step 4 if site information is not required
		if (siteInformationDisabled && flag === 0 && step === 3) {
			props.updateStepCode(5);
			overrideFlag = 1;
		}

		if (flag === 0 && step < 5 && overrideFlag === 0) {
			props.updateStepCode(step + 1);
		}
	}

	//Handler for popup ok button
	const popupOk = () => {
		setPopupShow(false);
		formReset();
	}

	//Handler for popup cancel button
	const popupCancel = () => {
		setPopupShow(false);
	}

	//Handler for product selection
	const productSelect = (product) => {
		logger.info("Inside of productSelect");
		setActivityTypes([]);
		props.updateActivityRequest('');
		props.updateSelectedProduct(product);
		setErrorMSG();
		setFileErrorMSG();
	}

	//Handler for activity requested selection
	const activityRequestedAction = (action, atype) => {
		logger.info("Inside of activityRequestedAction");
		logger.info(`selectedMACDProduct: ${selectedMACDProduct} action: ${action} atype: ${atype}`);
		props.updateActivityRequest(action);
		filterActivityTypes(action, atype);
		logger.info(`length of circuitList: ${Object.entries(circuitList)?.length}`);
		//Only fetch the circuitList if it is empty and product isn't any of the following: Colocation, SD-WAN, POTS
		if (Object.entries(circuitList)?.length === 0 && ![15, 24, 27].includes(selectedMACDProduct)) {
			fetchCircuitListCount();
		}

		//Disconnects, Voice Services and Pots don't need site information
		if (action === "Disconnect" || selectedMACDProduct === 26 || selectedMACDProduct === 27) {
			logger.info("Disable Step 4 for Disconnects,Voice Services, and POTS");
			setSiteInformationDisabled(true);
		} else {
			setSiteInformationDisabled(false);
		}

		//Colocation and SD WAN Disconnects do need site information
		if ((selectedMACDProduct === 15 || selectedMACDProduct === 24) && action === "Disconnect") {
			logger.info("Enabling Step 4 for Colocation or SD WAN Disconnects");
			setSiteInformationDisabled(false);
		}
		reviewOrderCreateFields(action, orderCreateForm);
		setErrorMSG();

	}

	//Handler for activity type selection
	const activityTypeAction = (activityType) => {
		logger.info(`Inside of activityTypeAction where activityType: ${JSON.stringify(activityType)}`);
		props.updateActivityType(activityType);
		setErrorMSG();
	}

	//Funciton to determine what activity types get showed based on product and activity requested
	const filterActivityTypes = (type, activityTypesProps) => {
		logger.info("Inside of filterActivityTypes");
		logger.info(`activityTypesProps: ${JSON.stringify(activityTypesProps)}`);
		logger.info(`Activity Type: ${type}`);
		let activityTypesArr = [];
		activityTypesProps.forEach(at => {
			if (type === 'Add') {
				if (selectedProduct?.activityTypes?.add.includes(at.macdId)) {
					activityTypesArr.push(at);
				}
			} else if (type === 'Change') {
				if (selectedProduct?.activityTypes?.change.includes(at.macdId)) {
					activityTypesArr.push(at);
				}
			} else if (type === 'Disconnect') {
				if (selectedProduct?.activityTypes?.disconnect.includes(at.macdId)) {
					activityTypesArr.push(at);
				}
			} else {
				logger.info('.....');
			}

		});

		logger.info(`activityTypesArr: ${JSON.stringify(activityTypesArr)}`);
		setActivityTypes(activityTypesArr);
	}

	/*Function to populate the Site Information Page based on the circuit id that is selected*/
	const findCircuit = (e) => {
		logger.info(e);
		let circuitVal = '';
		if (!e) {
			circuitVal = '';
		} else {
			circuitVal = e;
		}

		//setting value so that it display properly on the react select for circuit id
		props.updateCircuitDetails(circuitVal);
		//Sending just the circuit id to macd submit
		props.EXISTING_CIRCUIT_ID(circuitVal.label, "0");
		if (circuitVal.value !== "manual") {
			MACDHelper.getMACDCircuitValues(circuitVal.label).then(response => {
				logger.info(`getMACDCircuitValues: ${JSON.stringify(response.data)}`);
				props.A_ADDRESS(response.data.aloc_street);
				props.A_CITY(response.data.aloc_city);
				props.A_STATE(response.data.aloc_state);
				props.A_ZIP(response.data.aloc_zip);
				props.updateCircuitGaid(response.data.gaid);

				//Only Autofill location Z address for Waves and EAccess
				if (selectedMACDProduct === 1 || selectedMACDProduct === 20) {
					props.Z_ADDRESS(response.data.zloc_street);
					props.Z_CITY(response.data.zloc_city);
					props.Z_STATE(response.data.zloc_state);
					props.Z_ZIP(response.data.zloc_zip);
				}
			}).catch(error => {
				logger.error(`Error Getting Circuit Address Values: ${error}`);
				setHasError(error);
			})
		}

		setSelectedCircuit(e);
		setErrorMSG();
	}

	/*Validation Helper Function to determine which fields are mandatory in step 2*/
	const customerInfomationFields = (MACDOrderCreate) => {
		logger.info("Inside of customerInfomationFields");
		MACDOrderCreate.forEach(ocf => {
			if (ocf.code === "CONTACTS") {
				if (selectedMACDContacts.PRIMARY_AUTHORIZING_CONTACT === "new") {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "NEW_PRIMARY_AUTHORIZING_CONTACT") {
							ch.visible = true;
							ch.mandatory = true;
						} else {
							ch.visible = false;
							ch.mandatory = false;
						}
					})
				}

				if (selectedMACDContacts.DATA_TECHNICAL_CONTACT === "new") {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "NEW_DATA_TECHNICAL_CONTACT") {
							ch.visible = true;
							ch.mandatory = true
						} else {
							ch.visible = false;
							ch.mandatory = false
						}
					})
				}

			}
		})
		setOrderCreateForm(MACDOrderCreate);
	}

	/*Validation Helper Function to determine which fields are mandatory in Step 3*/
	const serviceInformationFields = (MACDOrderCreate) => {
		logger.info("Inside of serviceInformationFields");
		MACDOrderCreate.forEach(ocf => {
			if (ocf.code === "ServiceInformation") {
				// Public IP Address
				if ([1].includes(selectedActivityType) && selectedActivityRequest === 'Add') {
					logger.info("Service Information Validation for Public IP Address");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'CIRCUIT_TYPE', 'BANDWIDTH', 'QUANTITY', 'IPS'].includes(ch.code)) {
							ch.mandatory = true
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				} else {
					if (selectedActivityRequest === 'Add') {
						logger.info("Service Information Validation for all adds besides Public IP Address");
						ocf.characteristics.forEach(ch => {
							if (['REQUESTED_SERVICE_DATE', 'QUANTITY'].includes(ch.code)) {
								ch.mandatory = true;
								ch.visible = true;
							} else {
								ch.mandatory = false;
								ch.visible = false;
							}
						})
					}
				}

				//CABS Billing Change, CFA, Circuit Configuration, Circuit Regroom, MPLS-VPN
				if ([3, 4, 5, 6, 7].includes(selectedActivityType) && selectedActivityRequest === 'Change') {
					logger.info("Service Information Validation for CABS Billing Change, CFA, Circuit Configuration, Circuit Regroom, MPLS-VPN");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'CIRCUIT_TYPE', 'BANDWIDTH', 'QUANTITY'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				// Splice Request, Generic ICB Request, Records Confirmation
				if ([37, 40, 42].includes(selectedActivityType) && selectedActivityRequest === 'Change') {
					logger.info("Service Information Validation for Splice Request, OTDR (Test & Turnup), Generic ICB Request, Records Confirmation");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'NUMBER_OF_FIBERS'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				// OTDR (Test & Turnup)
				if ([38].includes(selectedActivityType) && selectedActivityRequest === 'Change') {
					logger.info("Service Information Validation for OTDR (Test & Turnup)");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'NUMBER_OF_FIBERS', 'TEST_TYPE', 'DIRECTION'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}
				//Submit Project Request
				if ([44].includes(selectedActivityType) && selectedActivityRequest === 'Change') {
					logger.info("Service Information Validation for Submit Project Request");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'NUMBER_OF_ROUTES', 'TEST_TYPE', 'DIRECTION'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				// Escort Access Request
				if(selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST){
					logger.info("Service Information Validation for Escort Access request");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				//Disconnects that don't have an activity type and aren't Colocation or Voice Services or POTS
				if (selectedActivityRequest === "Disconnect" && !selectedActivityType && ![15, 26, 27].includes(selectedMACDProduct)) {
					logger.info("Service Information Validation for Disconnects that aren't Colocation or POTS and don't have a selectedActivityType");
					ocf.characteristics.forEach(ch => {
						if (['END_BILLING_DATE', 'CIRCUIT_TYPE', 'BANDWIDTH', 'QUANTITY'].includes(ch.code)) {
							logger.info("Setting End Bill Date, circuit type, bandwidth, and quantity to mandatory values");
							ch.mandatory = true;
							ch.visible = true;
						}
						else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				//Colocation
				if (selectedMACDProduct === 15) {
					logger.info("Service Information Validation for Colocation");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						}
						else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				//SD-WAN
				if (selectedMACDProduct === 24) {
					logger.info("Service Information Validation for SD-WAN");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'EDGE_DEVICE'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						}
						else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				//POTS
				if (selectedMACDProduct === 27) {
					logger.info("Service Information Validation for POTS only");
					ocf.characteristics.forEach(ch => {
						if (['REQUESTED_SERVICE_DATE', 'QUANTITY', 'EXISTING_TN'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						} else {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}
			}
		});

		setOrderCreateForm(MACDOrderCreate);
	}


	/*Validation Helper Function to determine which fields are mandatory in Step 4*/
	const siteInformationFields = (MACDOrderCreate) => {
		logger.info("Inside of siteInformationFields");
		MACDOrderCreate.forEach(ocf => {
			//Location A Validation
			if (ocf.stepCode === "SiteInformation" && ocf.code === "LocationA") {
				//DS1, DS3 Circuit Requires these fields
				if (selectedMACDServiceInfo.CIRCUIT_TYPE === "1" || selectedMACDServiceInfo.CIRCUIT_TYPE === "2") {
					logger.info("Site Information Validation for DS1 and DS3");
					ocf.characteristics.forEach(ch => {
						if (ch.code === "A_LINE_CODE" || ch.code === "A_FRAMING") {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "A_LINE_CODE" || ch.code === "A_FRAMING") {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				/*Fiber Connector Types only for Waves and for certian handoff selections*/
				if (selectedMACDProduct === 1 || (selectedMACDServiceInfo.CIRCUIT_TYPE === "6" && ["6", "7", "8"].includes(selectedMACDLocationA.A_HANDOFF))) {
					logger.info("Site Information Validation for WAVES and Certain handoffs line 584");
					ocf.characteristics.forEach(ch => {
						if (ch.code === "A_FIBER_CONNECTOR") {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "A_FIBER_CONNECTOR") {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				/*Dark Fiber Requires Floor Value*/
				if (selectedMACDProduct === 30) {
					logger.info("Site Information Validation for Dark Fiber Adding Floor Value as mandatory");
					ocf.characteristics.forEach(ch => {
						if (ch.code === "A_FLOOR") {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "A_FLOOR") {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				//Colocation
				/*ADO-935601 Removing the frontend validation from making the following colo fields required
				if (selectedMACDProduct === 15) {
					logger.info("Colocation Validation");
					//Cross Connect and Change
					if(selectedActivityType === "46" && selectedActivityRequest === "Change"){
						logger.info("Colocation Cross Connect Change Validation");
						ocf.characteristics.forEach(ch => {
							if (['CONNECTOR_TYPE', 'LIVE_EQUIPMENT', 'RACK_INFO', 'PANEL_INFO', 'PORT_FIBER_ASSIGNMENT'].includes(ch.code)) {
								ch.mandatory = true;
								ch.visible = true;
							}
						})
					//Full or Cross Connect Disconnect
					} else if((["32","48"].includes(selectedActivityType)) && selectedActivityRequest === "Disconnect"){
						logger.info(`${selectedActivityType} ${selectedActivityRequest} Validation`);
						ocf.characteristics.forEach(ch => {
							if (['RACK_INFO', 'PANEL_INFO', 'PORT_FIBER_ASSIGNMENT'].includes(ch.code)) {
								ch.mandatory = true;
								ch.visible = true;
							}
						})
					//Space and Power Disconnect
					} else if(selectedActivityType === "49" && selectedActivityRequest === "Disconnect"){
						logger.info("Space and Power Disconnect Validation");
						ocf.characteristics.forEach(ch => {
							if (['RACK_INFO'].includes(ch.code)) {
								ch.mandatory = true;
								ch.visible = true;
							}
						})
					} else {
						ocf.characteristics.forEach(ch => {
							if (['CONNECTOR_TYPE', 'LIVE_EQUIPMENT', 'RACK_INFO', 'PANEL_INFO', 'PORT_FIBER_ASSIGNMENT'].includes(ch.code)) {
								ch.mandatory = false;
								ch.visible = false;
							}
						})
					}
				}
				*/
			}

			let isUniti = false;
			//Location Z Validation
			if (ocf.stepCode === "SiteInformation" && ocf.code === "LocationZ") {
				logger.info(`selectedMACDProduct: ${selectedMACDProduct} selectedActivityType: ${selectedActivityType} MPID: ${mpid} `);
				if ((selectedMACDProduct === 30)) {
					if ([44].includes(selectedActivityType)) {
						if ((Number(mpid) === 4718 || Number(mpid) === 8722 || Number(selectedInternalMpid) === 4718 || Number(selectedInternalMpid) === 8722)) {
							logger.info("Setting Uniti to true");
							isUniti = true;
						}
					}
				}
				if (!isUniti && (selectedMACDProduct === 1 || selectedMACDProduct === 20 || (selectedMACDProduct === 30 && [38, 42, 44].includes(selectedActivityType)))) {
					logger.info("Site Information Validation for Waves, EAccess, and Dark Fiber OTDR, ICB, and Submit Project Request line 597");
					ocf.characteristics.forEach(ch => {
						if (['Z_ADDRESS', 'Z_CITY', 'Z_STATE', 'Z_ZIP', 'Z_ONSITE_NAME_LCON', 'Z_ONSITE_NUMBER', 'Z_ONSITE_EMAIL'].includes(ch.code)) {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (['Z_ADDRESS', 'Z_CITY', 'Z_STATE', 'Z_ZIP', 'Z_ONSITE_NAME_LCON', 'Z_ONSITE_NUMBER', 'Z_ONSITE_EMAIL'].includes(ch.code)) {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				//DS1, DS3 Circuit Requires these fields
				if (selectedMACDServiceInfo.CIRCUIT_TYPE === "1" || selectedMACDServiceInfo.CIRCUIT_TYPE === "2") {
					logger.info("Site Information Validation for DS1 and DS3");
					ocf.characteristics.forEach(ch => {
						if (ch.code === "Z_LINE_CODE" || ch.code === "Z_FRAMING") {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "Z_LINE_CODE" || ch.code === "Z_FRAMING") {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				/*Fiber Connector Types only for Waves and for certian handoff selections*/
				if (selectedMACDProduct === 1 || (selectedMACDServiceInfo.CIRCUIT_TYPE === "6" && ["6", "7", "8"].includes(selectedMACDLocationZ.Z_HANDOFF))) {
					logger.info("Site Information Validation for Waves and Certain Handoffs line 619");
					ocf.characteristics.forEach(ch => {
						if (ch.code === "Z_FIBER_CONNECTOR") {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "Z_FIBER_CONNECTOR") {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

				/*Dark Fiber Requires Floor Value*/
				if (!isUniti && selectedMACDProduct === 30 && [38, 42, 44].includes(selectedActivityType)) {
					logger.info("Site Information Validation for Dark Fiber Adding Floor Value as mandatory");
					ocf.characteristics.forEach(ch => {
						if (ch.code === "Z_FLOOR") {
							ch.mandatory = true;
							ch.visible = true;
						}
					})
				} else {
					ocf.characteristics.forEach(ch => {
						if (ch.code === "Z_FLOOR") {
							ch.mandatory = false;
							ch.visible = false;
						}
					})
				}

			}

			//SDWAN Validation for Edge Devices
			if (ocf.stepCode === "SiteInformation" && ocf.code === "ShippingLocation" && selectedMACDProduct === 24 && selectedMACDServiceInfo.EDGE_DEVICE === "2") {
				logger.info("SDWAN Validation for Edge Devices with leased selected");
				ocf.characteristics.forEach(ch => {
					if (['SHIPPING_ADDRESS', 'SHIPPING_CITY', 'SHIPPING_STATE', 'SHIPPING_ZIP', 'SHIPPING_CONTACT', 'SHIPPING_PHONE', 'SHIPPING_PHONE'].includes(ch.code)) {
						ch.mandatory = true;
						ch.visible = true;
					} else {
						ch.mandatory = false;
						ch.visible = false;
					}
				})
			}
		})
		setOrderCreateForm(MACDOrderCreate);
	}

	/*Validation Helper Function to determine which fields are mandatory in Step 5*/
	const reviewOrderCreateFields = (type, MACDOrderCreate) => {
		MACDOrderCreate.forEach(ocf => {
			if (ocf.stepCode === "ReviewCreateOrder") {
				let checkVisible = false;
				if ((type === 'Add' || type === 'Disconnect') && ocf.code === "Order_Notes") {
					checkVisible = true;
				} else if (type === 'Change' && ocf.code === "Insert_Notes") {
					checkVisible = true;
				}
				else {
					checkVisible = false;
				}

				ocf.visible = checkVisible;

				if (ocf.code === "File_Attachment") {
					ocf.visible = true;
				}
			}
		});
		setOrderCreateForm(MACDOrderCreate);
	}


	//Step 5 Order Review
	useEffect(() => {
		if (step === 5) {
			setLoader(true);
			OrderHelper.getOrderUploadTypes().then(response => {
				logger.info(`Getting Upload Types: ${JSON.stringify(response.data)}`);
				setUploadTypeOptions(response.data);
				setLoader(false);
			}).catch(error => {
				logger.error(`Getting Upload Types ERROR: ${error}`);
				setHasError(error);
				setLoader(false);
			})
		}

		//Only for Dark Fiber which require the lease fee section
		if (!selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST && step === 5 && selectedMACDProduct === 30) {
			setLoader(true);
			MACDHelper.getDarkFiberPricing(selectedActivityType, selectedMACDServiceInfo.NUMBER_OF_FIBERS, selectedMACDServiceInfo.TEST_TYPE, selectedMACDServiceInfo.DIRECTION, selectedMACDServiceInfo.NUMBER_OF_ROUTES, selectedMACDServiceInfo.SPLICE_ARRAY, selectedMACDServiceInfo.FIBER_ARRAY).then(response => {
				setLeaseFeeData(response.data);
				logger.info(`Lease Fee Data: ${JSON.stringify(response.data)}`);
				setLoader(false);
			}).catch(error => {
				logger.error(`Error getting dark fiber pricing: ${error}`);
				setHasError(error);
				setLoader(false);
			})
		}
	}, [logger, selectedActivityType, selectedMACDProduct, selectedMACDServiceInfo.DIRECTION, selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST, selectedMACDServiceInfo.FIBER_ARRAY, selectedMACDServiceInfo.NUMBER_OF_FIBERS, selectedMACDServiceInfo.NUMBER_OF_ROUTES, selectedMACDServiceInfo.SPLICE_ARRAY, selectedMACDServiceInfo.TEST_TYPE, step])

	//Handler for File Type Selection
	const handleFileType = (event) => {
		setFileErrorMSG("");
		setFileType(event.target.value);
		setFileTypeName(event.target[event.target.selectedIndex].text);
	}

	//Handler for File Upload Event
	const handleFileEvent = (event) => {
		const regex = /^[A-Za-z0-9_.\- ()]+$/;
		const filename = event.target.files[0].name;
		logger.info(`File Name: ${filename}`);
		if (!filename.match(regex)) {
			setFileErrorMSG("Filenames can only contain letters, numbers, underscore, hyphen, spaces, parenthesis and a period. ");
		} else {
			const uploaded = [...uploadedFiles];
			//Max of 5 files
			if (fileCounter >= 4) {
				setFileLimit(true);
			}
			//Adding Upload type selected to the store
			props.MACD_UPLOADED_FILES(fileType, fileCounter);
			uploaded.push(`${fileCounter + 1}. File Type: ${fileTypeName}  -  Filename: ${event.target.files[0].name}`);
			switch (fileCounter) {
				case 0:
					setFile1(event.target.files[0]);
					break;
				case 1:
					setFile2(event.target.files[0]);
					break;
				case 2:
					setFile3(event.target.files[0]);
					break;
				case 3:
					setFile4(event.target.files[0]);
					break;
				case 4:
					setFile5(event.target.files[0]);
					break;
				default:
					break;
			}
			setUploadedFiles(uploaded);
			setFileCounter(fileCounter + 1);
			setFileType("");
			setFileTypeName("");
		}
	}

	//Handler for MACD Submit
	const macdSubmit = () => {
		logger.info("MACD SUBMIT CLICKED");
		setSubmitLoader(true);
		let cpt_activity = "";

		//Mapping Activity Requested Value to CPT Value Used in the DB
		if (selectedActivityRequest === "Add") {
			cpt_activity = "A";
		} else if (selectedActivityRequest === "Change") {
			cpt_activity = "C";
		} else {
			cpt_activity = "D";
		}

		//Creating formData object to post upload file to axios call
		const formData = new FormData();
		formData.append("userfile0", file1);
		formData.append("userfile1", file2);
		formData.append("userfile2", file3);
		formData.append("userfile3", file4);
		formData.append("userfile4", file5);

		//Mapping Redux Store values to existing cpt values that are used in orderACDSubmit.php
		let macdJson = {
			"cpt_activity": cpt_activity,
			"macd_type": selectedActivityType,
			"acna": selectedMACDGeneralInfo.ACNA,
			"new_orig_contact": selectedMACDContacts.NEW_PRIMARY_AUTHORIZING_CONTACT,
			"orig_contact": selectedMACDContacts.PRIMARY_AUTHORIZING_CONTACT,
			"orig_phone": selectedMACDContacts.PRIMARY_AUTHORIZING_PHONE,
			"orig_email": selectedMACDContacts.PRIMARY_AUTHORIZING_EMAIL,
			"new_tech_contact": selectedMACDContacts.NEW_DATA_TECHNICAL_CONTACT,
			"tech_contact": selectedMACDContacts.DATA_TECHNICAL_CONTACT,
			"tech_phone": selectedMACDContacts.DATA_TECHNICAL_PHONE,
			"tech_email": selectedMACDContacts.DATA_TECHNICAL_EMAIL,
			"cust_circuit": selectedMACDGeneralInfo.CUSTOMER_CIRCUIT,
			"cust_pon": selectedMACDGeneralInfo.CUSTOMER_PON,
			"leasenet_id": selectedMACDGeneralInfo.LEASENET_ID,
			"exist_circuit_colo": selectedMACDGeneralInfo.CIRCUIT_ID,
			"escort_request": selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST,
			"serv_date": selectedMACDServiceInfo.REQUESTED_SERVICE_DATE,
			"product": selectedMACDProduct,
			"ex_circuit": selectedMACDServiceInfo.EXISTING_CIRCUIT_ID,
			"exist_circuit": selectedMACDServiceInfo.EXISTING_TN,
			"cableFID": selectedMACDServiceInfo.CABLE_FID,
			"circuit": selectedMACDServiceInfo.CIRCUIT_TYPE,
			"numFibers": selectedMACDServiceInfo.NUMBER_OF_FIBERS,
			"numRoutes": selectedMACDServiceInfo.NUMBER_OF_ROUTES,
			"testType": selectedMACDServiceInfo.TEST_TYPE,
			"direction": selectedMACDServiceInfo.DIRECTION,
			"designNum": selectedMACDServiceInfo.DESIGN_INQUIRY_NUM,
			"fiberArray": selectedMACDServiceInfo.FIBER_ARRAY,
			"spliceArray": selectedMACDServiceInfo.SPLICE_ARRAY,
			"qty": selectedMACDServiceInfo.QUANTITY,
			"dispatch": selectedMACDServiceInfo.DISPATCH_OUTSIDE_WIN_BUSINESS_HOURS,
			"disco_network": selectedMACDServiceInfo.DISCO_NETWORK,
			"accountNumber": selectedMACDServiceInfo.ACCOUNT_NUMBER,
			"disco_account": selectedMACDServiceInfo.ACCOUNT_NUMBER,
			"manualCircuit": selectedMACDServiceInfo.MANUAL_CIRCUIT,
			"loop_band": {
				"1": selectedMACDServiceInfo.BANDWIDTH
			},
			"ips": selectedMACDServiceInfo.IPS,
			"transact_type": selectedMACDServiceInfo.EDGE_DEVICE,
			"la_add": selectedMACDLocationA.A_ADDRESS,
			"la_add2": selectedMACDLocationA.A_A_ADDRESS2,
			"la_city": selectedMACDLocationA.A_CITY,
			"la_state": selectedMACDLocationA.A_STATE,
			"la_zip": selectedMACDLocationA.A_ZIP,
			"la_ld2": "FL",
			"la_lv2": selectedMACDLocationA.A_FLOOR,
			"a_lcon_name": selectedMACDLocationA.A_ONSITE_NAME_LCON,
			"a_lcon_phone": selectedMACDLocationA.A_ONSITE_NUMBER,
			"a_lcon_email": selectedMACDLocationA.A_ONSITE_EMAIL,
			"a_handoff": selectedMACDLocationA.A_HANDOFF,
			"a_framing": selectedMACDLocationA.A_FRAMING,
			"a_line_code": selectedMACDLocationA.A_LINE_CODE,
			"a_fiber_conn": selectedMACDLocationA.A_FIBER_CONNECTOR,
			"connector_type": selectedMACDLocationA.CONNECTOR_TYPE,
			"live_equipment": selectedMACDLocationA.LIVE_EQUIPMENT,
			"rack_info": selectedMACDLocationA.RACK_INFO,
			"panel_info": selectedMACDLocationA.PANEL_INFO,
			"port_fiber_assignment": selectedMACDLocationA.PORT_FIBER_ASSIGNMENT,
			"lz_add": selectedMACDLocationZ.Z_ADDRESS,
			"lz_add2": selectedMACDLocationZ.Z_Z_ADDRESS2,
			"lz_city": selectedMACDLocationZ.Z_CITY,
			"lz_state": selectedMACDLocationZ.Z_STATE,
			"lz_zip": selectedMACDLocationZ.Z_ZIP,
			"lz_ld2": "FL",
			"lz_lv2": selectedMACDLocationZ.Z_FLOOR,
			"z_lcon_name": selectedMACDLocationZ.Z_ONSITE_NAME_LCON,
			"z_lcon_phone": selectedMACDLocationZ.Z_ONSITE_NUMBER,
			"z_lcon_email": selectedMACDLocationZ.Z_ONSITE_EMAIL,
			"z_handoff": selectedMACDLocationZ.Z_HANDOFF,
			"z_framing": selectedMACDLocationZ.Z_FRAMING,
			"z_line_code": selectedMACDLocationZ.Z_LINE_CODE,
			"z_fiber_conn": selectedMACDLocationZ.Z_FIBER_CONNECTOR,
			"sa_co_address": selectedMACDShippingLocation.SHIPPING_ADDRESS,
			"sa_ld1": selectedMACDShippingLocation.SHIPPING_LD1,
			"sa_lv1": selectedMACDShippingLocation.SHIPPING_LV1,
			"sa_ld2": "FL",
			"sa_lv2": selectedMACDShippingLocation.SHIPPING_LV2,
			"sa_ld3": selectedMACDShippingLocation.SHIPPING_LD3,
			"sa_lv3": selectedMACDShippingLocation.SHIPPING_LV3,
			"sa_city": selectedMACDShippingLocation.SHIPPING_CITY,
			"sa_state": selectedMACDShippingLocation.SHIPPING_STATE,
			"sa_zip": selectedMACDShippingLocation.SHIPPING_ZIP,
			"ship_contact_id": selectedMACDShippingLocation.SHIPPING_CONTACT,
			"ship_new_contact": selectedMACDShippingLocation.SHIPPING_CONTACT_NEW,
			"ship_phone": selectedMACDShippingLocation.SHIPPING_PHONE,
			"ship_email": selectedMACDShippingLocation.SHIPPING_EMAIL,
			"upload_file_type": selectedMACDFiles.FILE_TYPE,
			"notes": selectedMACDNotes
		};

		logger.info(`MACD Submit JSON: ${JSON.stringify(macdJson)}`);
		//Calling axios service to call orderACDSubmit.php
		MACDHelper.createMACD(formData, macdJson).then(response => {
			if (response.data?.result === "SUCCESS") {
				setRefId(response.data?.ref_id);
				setRedirectFlag(true);
				logger.info(`Redirect to Order View Page with the following ref id: ${response.data?.ref_id}`);
			} else {
				setMACDErrorMsg("Error submitting MACD order. " + response.data?.error);
				setRedirectFlag(false);
			}
			setSubmitLoader(false);
		}).catch(error => {
			logger.error(`createMACD ERROR: ${error}`);
			setHasError(error);
			setSubmitLoader(false);
		})

	}

	const customCircuitIdFilter = (option, searchText) => {
		let sanitizedSearchText = searchText.replace(/\W/g, "").toUpperCase();
		return option.value.includes(sanitizedSearchText);
	}

	useEffect(() => {
		//setting the OrderCreateForm to all the fields in MACD_Create.json
		//MACD_Create.json stores all availables fields for MACD and is used for validation
		if (isCustomer || (isEmployee && selectedInternalMpid)) {
			Api.fetchMACDCreate().then(response => {
				setOrderCreateForm(response.data);
			}).catch(error => {
				logger.error(`fetchMACDCreate ERROR: ${error}`);
				setHasError(error);
			})
		}
	}, [logger, selectedInternalMpid, isEmployee, isCustomer])

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<div className="midsection om-create abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Create MAC-D" : "Create MAC-D"}</h1>
						{isEmployee ? <CompanyDropdown area={"MACD"} stepCode={step}></CompanyDropdown> : null}
						<div className="section">
							<TabsOrderManager />
							<div className={`content has-actions ${style.orderCreatePage}`}>
								<div className="milestones five-steps">
									<div className={`step ${step === 1 ? 'active' : ''} `}>
										<div>1</div>
										<span>Product Selection</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${step === 2 ? 'active' : ''} `}>
										<div>2</div>
										<span>Customer Information</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${step === 3 ? 'active' : ''} `}>
										<div>3</div>
										<span>Service Information</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${step === 4 ? 'active' : ''} ${siteInformationDisabled ? (style.disabled) : ''} `}>
										<div>4</div>
										<span>Site Information</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${step === 5 ? 'active' : ''} `}>
										<div>5</div>
										<span>Review &amp; Create Order</span>
									</div>
								</div>

								<div className='my-5'>

									{step !== 1 ? <p className={style.denoteText}><span className={style.mandatory}>* </span>Denotes a required field</p> : null}

									{step === 1 ?
										<div className='step step-1 text-center'>
											<div className='product-wrap'>
												{products.length > 0 || (userFlag && isCustomer) ?
													<>
														{products.map((pd, i) =>
															<div key={i} className={` ${style.productThumb} ${selectedProduct?.code === pd.code ? style.selected : selectedProduct === '' ? '' : style.notSelected}   `} onClick={() => { productSelect(pd) }}>
																<h1 className={` ${style.productLink} `} >
																	{pd.productName}
																</h1>
																<div className={` ${style.productType}`}></div>
																<img alt={pd.productName} src={`../../images/product_icons/${encodeURIComponent(pd.code)}.svg `} className={style.productImgQuotePage} />
																<svg className={`ico ${style.ptCheck}`}><path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path></svg>
															</div>
														)}
													</> :
													<>
														{isEmployee ?
															<>
																{!selectedInternalMpid ? <h1>Please select company</h1> : <h1>Loading Products</h1>}
															</> : <h1>Loading Products</h1>
														}
													</>
												}
											</div>

											{selectedProduct !== '' ?
												<div className='activityRequested'>
													<div className={`sub-hdr text-left alt1 ${style.subHeader} `}>
														<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={`{style.sectionTitle} m-0`}>Activity Requested</h2>
														<p className='text-left mb-0 mt-2'>Please select the order activity:</p>
													</div>

													{['MPLS_INTERNET', 'DIA_POP', 'DIA_PREM'].includes(selectedProduct?.code) ?
														<div className={`productSubTypeGroup ${style.subGrp} `}>
															<a href className={` d-block ${selectedActivityRequest === 'Add' ? style.selected : ''}`} onClick={() => { activityRequestedAction('Add', activityTypesList) }} >
																<svg className={`ico ${style.configOptIco} `}><path d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path></svg>
																<svg className={`ico ${style.configOptCheck} `}><path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path></svg>
																Add
															</a>
														</div>
														: null}

													{!['SD_WAN', 'VOICE_SERVICES'].includes(selectedProduct?.code) ?
														<div className={`productSubTypeGroup ${style.subGrp} `}>
															<a href className={` d-block ${selectedActivityRequest === 'Change' ? style.selected : ''}`} onClick={() => { activityRequestedAction('Change', activityTypesList) }} >
																<svg className={`ico ${style.configOptIco} `}><path d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path></svg>
																<svg className={`ico ${style.configOptCheck} `}><path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path></svg>
																Change
															</a>
														</div> : null
													}
													{selectedProduct?.code !== "DARK_FIBER" ?
														<div className={`productSubTypeGroup ${style.subGrp} `}>
															<a href className={` d-block ${selectedActivityRequest === 'Disconnect' ? style.selected : ''}`} onClick={() => { activityRequestedAction('Disconnect', activityTypesList) }} >
																<svg className={`ico ${style.configOptIco} `}><path d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path></svg>
																<svg className={`ico ${style.configOptCheck} `}><path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path></svg>
																Disconnect
															</a>
														</div> : null
													}

													{/* )} */}
												</div>
												: null}

											{selectedActivityRequest !== '' ?
												<div className='activityRequested'>
													{activityTypes.length > 0 ?
														<div className={`sub-hdr text-left alt2 ${style.subHeader} `}>
															<div className="nib nib1"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={`{style.sectionTitle} m-0`}>Activity Type</h2>
															<p className='text-left mb-0 mt-2'>Please select the type of activity to take:</p>
														</div>
														: null}

													{activityTypes.map((at, i) =>
														<div key={i} className={`productSubTypeGroup ${style.subGrp} `}>
															<a href className={` d-block ${selectedActivityType === Number(at.macdId) ? style.selected : ''}`} onClick={() => { activityTypeAction(at) }} >
																<svg className={`ico ${style.configOptIco} `}><path d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path></svg>
																<svg className={`ico ${style.configOptCheck} `}><path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path></svg>
																{at.name}
															</a>
														</div>
													)}
												</div>
												: null}

											{selectedMACDProduct === 15 || selectedMACDProduct === 24 ? /* Colocation and SD-WAN only needs account number no circuit */
												<>
													<div className={`sub-hdr text-left alt3 ${style.subHeader} `}>
														<div className="nib nib1"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={`{style.sectionTitle} m-0`}>Enter Account Number</h2>
														<div className="row mb-3">
															<div className={` col-5 ${style.colGrp}`}>
																<p className='text-left'>Please manually enter your Account Number:</p>
															</div>
															<div className={` col-4 col-sm-4 col-md-2 col-lg-2 ${style.colGrp}`}>
																<input type='text' className={`${style.inputFld}`} id="account_number" name="account_number" onChange={(ev) => props.ACCOUNT_NUMBER(ev.target.value)} value={selectedMACDServiceInfo.ACCOUNT_NUMBER}></input>
															</div>
														</div>
													</div>
												</>
												: null
											}

											{/*Display Select Circuit as long as the product isn't one of the follow: Colocation, SD-WAN, or POTS*/}
											{![15, 24, 27].includes(selectedMACDProduct) && selectedMACDProduct && selectedActivityRequest ?
												<div className='circuitDetails'>
													<div className={`sub-hdr text-left alt3 ${style.subHeader} `}>
														<div className="nib nib1"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={`{style.sectionTitle} m-0`}>Circuit Details</h2>
														<p className='text-left mb-0 mt-2'>Please select the Circuit:</p>
													</div>
													{Number(selectedCircuitListCount) > 5000 ?
														<div className="d-flex justify-content-center">
															<div className={` col-4 col-sm-4 col-md-2 col-lg-2 ${style.colGrp} mt-4`}>
																<input type='text' className={`${style.inputFld}`} id="searchCircuit" name="searchCircuit" onChange={(ev) => props.updateCircuitSearch(ev.target.value)} value={selectedCircuitSearch}></input>
																<p className='text-danger'>{`There are ${selectedCircuitListCount} circuits available. Please provide part of the Circuit ID and click search.`}</p>
															</div>
															<div className={` col-1 col-sm-1 col-md-1 col-lg-1 ${style.colGrp}`}>
																<button type="button" className={`ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => searchCircuitList()}>Search</button>
															</div>
														</div> : null
													}


													{Number(selectedCircuitListCount) < 5000 ?
														<form>
															<div className="w-25 m-auto">
																<div className="rel mb-5 text-left">
																	<Select
																		defaultValue={macDFromStore.selectedCircuitDetails}
																		onChange={(e) => { findCircuit(e) }}
																		options={circuitList.map(item => {
																			return { value: item.circuit, label: item.circuitId };
																		})}
																		isSearchable
																		isClearable
																		filterOption={customCircuitIdFilter}
																		noOptionsMessage={() => { return <a href="https://customers.windstreamwholesale.com/" className="text-danger">Not seeing your CID, click here to search the legacy portal</a> }} />
																</div>
															</div>
														</form> : null}

												</div>
												: null}
											{/*MANUAL CIRCUIT */}
											{macDFromStore?.selectedCircuitDetails?.value === "manual" ?
												<>
													<div className={`sub-hdr text-left alt3 ${style.subHeader} `}>
														<div className="nib nib1"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={`{style.sectionTitle} m-0`}>Manually Enter Circuit ID</h2>
														<p className='text-left mb-0 mt-2'>Please enter your Circuit:</p>
													</div>
													<div className="d-flex justify-content-center">
														<div className={` col-4 col-sm-4 col-md-2 col-lg-2 ${style.colGrp}`}>
															<input type='text' className={`${style.inputFld}`} id="manual_circuit" name="manual_circuit" onChange={(ev) => props.MANUAL_CIRCUIT(ev.target.value, 0)} value={selectedMACDServiceInfo.MANUAL_CIRCUIT[0]}></input>
														</div>
													</div>
													<div className={`sub-hdr text-left alt3 ${style.subHeader} `}>
														<div className="nib nib1"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={`{style.sectionTitle} m-0`}>Circuit Identification</h2>
														<p className='text-left mb-0 mt-2'>Please choose a circuit identification:</p>
													</div>
													<div className="d-flex justify-content-center">
														<div className={` col-4 col-sm-4 col-md-2 col-lg-2 ${style.colGrp}`}>
															<select className={style.selectFld} id='disco_network' name='disco_network' onChange={(ev) => props.DISCO_NETWORK(ev.target.value)} value={selectedMACDServiceInfo.DISCO_NETWORK}>
																<option></option>
																<option value="DOV">DOV</option>
																<option value="ELNK">ELNK</option>
																<option value="FROT">FROT</option>
																<option value="GTES">GTES</option>
																<option value="IATS">IATS</option>
																<option value="IFN">IFN</option>
																<option value="IOR">IOR</option>
																<option value="ITCD">ITCD</option>
																<option value="KDL">KDL</option>
																<option value="NLT">NLT</option>
																<option value="NUVX">NUVX</option>
																<option value="PA">PA</option>
																<option value="PUA">PUA</option>
																<option value="SB">SB</option>
																<option value="VLR">VLR</option>
																<option value="WIN">WIN</option>
																<option value="WXN">WXN</option>
															</select>
														</div>
													</div>

												</> : null
											}
										</div>
										: null}



									{step === 2 ?
										<MACDCustomerInfo /> : null
									}

									{step === 3 ?
										<MACDServiceInfo mpid={mpid} /> : null
									}

									{step === 4 ?
										<MACDSiteInfo /> : null
									}

									{step === 5 ?
										<>
											<div className={style.section1}>
												<div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
													<div className="nib">
														<div className="the-notch">
															<div className="notch-fg">
																<svg>
																	<path
																		d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																</svg>
															</div>
														</div>
													</div>
													<h2 className={` ${style.sectionTitle} m-0 `}>File Attachment</h2>
												</div>
												<p className='text-danger mt-3'>{fileErrorMSG}</p>
												<div className="sub-section">
													<div className={` row ${style.rowGrp}`}>
														<div className={` col-12 col-sm-6 col-md-4 ${style.colGrp}`}>
															<label className={style.labelText}>File Type</label>
															<select className={` ${style.selectFld}`} onChange={handleFileType} value={fileType}>
																{uploadTypeOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}
															</select>
														</div>
														<div className={` col-12 col-sm-6 col-md-4 ${style.colGrp}`}>
															<label htmlFor="uploadFile" className={style.uploadLabel}>
																<div className={style.uploadLabelText}>Browse...</div>
															</label>
															<input type='file' className={style.uploadFile} multiple onChange={handleFileEvent} disabled={fileLimit || !fileTypeName} />
														</div>
													</div>
													<div className={` row ${style.rowGrp}`}>
														<div className={` col-12 col-sm-6 col-md-4 ${style.colGrp}`}>
															{uploadedFiles.map((file, i) => {
																return (
																	<div key={i}>
																		{file}
																	</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className={style.dividers}>
											</div>

											<div className={style.section1}>
												<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
													<div className="nib">
														<div className="the-notch">
															<div className="notch-fg">
																<svg>
																	<path
																		d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																</svg>
															</div>
														</div>
													</div>
													<h2 className={` ${style.sectionTitle} m-0 `}>Insert Notes</h2>
												</div>
												<div className="sub-section">
													<div className={` row ${style.rowGrp}`}>
														<div className={` col-12 col-sm-6 col-md-8 ${style.colGrp}`}>
															<label className={style.labelText}>Notes</label>
															<textarea className={style.textAreaFld} id='notes' name='notes' onChange={(ev) => props.ORDER_NOTES(ev.target.value)} value={selectedMACDNotes}></textarea>
														</div>
													</div>
												</div>
											</div>
											<div className={style.dividers}>
											</div>
											{/*LEASE FEE SECTION ONLY AVAILABLE FOR DARK FIBER THAT AREN'T RECORD CONFIRMATION OR ICB GENERIC REQUEST*/}
											{selectedMACDProduct === 30 && [37, 43, 44].includes(selectedActivityType) && !selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST ?
												<>
													<div className={style.section5}>
														<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
															<div className="nib">
																<div className="the-notch">
																	<div className="notch-fg">
																		<svg>
																			<path
																				d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																		</svg>
																	</div>
																</div>
															</div>
															<h2 className={` ${style.sectionTitle} m-0 `}>Lease Fee</h2>
														</div>


														<div className="sub-section">
															<div className={` row ${style.rowGrp}`}>
																<div className='col-12'>
																	<table className={` table ${style.leaseTable} text-center`}>
																		<thead>
																			<tr>
																				<th>Item(s)</th>
																				<th>Quantity</th>
																				<th>MRC</th>
																				<th>NRC</th>
																				<th>Total MRC <sup>1</sup></th>
																				<th>Total NRC <sup>2</sup></th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<td>{leaseFeeData.item}</td>
																				<td>{leaseFeeData.qty}</td>
																				<td>${leaseFeeData.mrc}</td>
																				<td>${leaseFeeData.nrc}</td>
																				<td>${leaseFeeData.total_mrc}</td>
																				<td>${leaseFeeData.total_nrc}</td>
																			</tr>
																			{leaseFeeData.item2?.length > 0 ?
																				<tr>
																					<td>{leaseFeeData.item2}</td>
																					<td>{leaseFeeData.qty}</td>
																					<td>${leaseFeeData.mrc2}</td>
																					<td>${leaseFeeData.nrc2}</td>
																					<td>${leaseFeeData.total_mrc2}</td>
																					<td>${leaseFeeData.total_nrc2}</td>
																				</tr> : null
																			}
																			<tr>
																				<td>Overall Totals</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td>${leaseFeeData.overall_mrc}</td>
																				<td>${leaseFeeData.overall_nrc}</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
														<div className={style.dividers}></div>

													</div>
												</> : null
											}
											{submitLoader ?
												<>
													<Loader />
													<div className="mt-5">
														<p className="text-success text-center">Submitting MACD Order...</p>
													</div>
												</> : null
											}
											{macdErrorMsg?.length > 0 ?
												<div className="mt-5">
													<p className="text-danger text-center">{macdErrorMsg}</p>
												</div> : null
											}
											{redirectFlag ?
												<>
													<div className="mt-5">
														<p className="text-success text-center">MACD ORDER CREATED REF ID:  {refId}</p>
													</div>
													<Redirect to={`/Order/View/${refId}`} />
												</> : null
											}
										</> : null
									}

									{/*Step Buttons*/}
									<div className={`${style.pageActions}`}>
										<div className="text-center">
											<p className='text-danger mt-3'>{errorMSG}</p>
											<p className='text-success mt-3'>{successMSG}</p>

											<button className={` ico-button light-button `} onClick={cancelAction} >
												Cancel
												<svg className="ico-x">
													<path
														d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
												</svg>
											</button>

											{
												step !== 1 ?
													<button className={` ico-button lhs light-button ${style.nextBtn} ${style.prevBtn} ml-3`} onClick={() => prevPage()}>
														<svg className="ico light-ico ico-arrow">
															<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
														</svg>
														{stepList[step - 2]}
													</button>
													: null

											}
											{
												step === 5 ?
													<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => { macdSubmit() }}>
														Submit Order
														<svg className="ico light-ico ico-arrow">
															<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
														</svg>
													</button>
													:
													<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => { nextPage() }}>
														{stepList[step]}
														<svg className="ico light-ico ico-arrow">
															<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
														</svg>
													</button>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{
					popupShow ?
						<div>
							<div className={`${style.overly}`}> </div>
							<div className="modal fade show d-block">
								<div className={` modal-dialog modal-dialog-centered modal-sm`}>
									<div className="modal-content">
										<div className="modal-body">
											Are you sure you want to cancel?
										</div>

										<div className="modal-footer d-block text-center">
											<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={popupOk}>Ok</button>
											<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
										</div>

									</div>
								</div>
							</div>
						</div>
						: null
				}
			</div >)}
		</>
	);
}

// export default MACD;

const mapDispatchToProps = {
	updateStepCode: setMACDStepCode,
	updateSelectedProduct: setMACDProduct,
	updateActivityRequest: setMACDActivityRequest,
	updateActivityType: setMACDActivityType,
	updateCircuitListCount: setMACDCircuitListCount,
	updateCircuitGaid: setMACDCircuitGaid,
	updateCircuitSearch: setMACDCircuitSearch,
	updateCircuitDetails: setMACDCircuitDetails,
	EXISTING_CIRCUIT_ID: setMACDExistingCircuit,
	ACCOUNT_NUMBER: setMACDAccountNumber,
	MANUAL_CIRCUIT: setMACDManualCircuit,
	DISCO_NETWORK: setMACDDiscoNetwork,
	A_ADDRESS: setMACDAAddress,
	A_ADDRESS2: setMACDAAddress2,
	A_CITY: setMACDACity,
	A_STATE: setMACDAState,
	A_ZIP: setMACDAZip,
	Z_ADDRESS: setMACDZAddress,
	Z_ADDRESS2: setMACDZAddress2,
	Z_CITY: setMACDZCity,
	Z_STATE: setMACDZState,
	Z_ZIP: setMACDZZip,
	MACD_UPLOADED_FILES: setMACDUploadType,
	INSERT_NOTES: setMACDInsertNotes,
	ORDER_NOTES: setMACDOrderNotes,
	clearMACDStore: resetMACDData
	// updateErrorMsg: tcErrorMsg,
}

export default connect(null, mapDispatchToProps)(MACD);
